import { debounce, getLargestHeight } from './utility/UtilityFunctions';
import './modules/Navigation';
import './modules/ResetWaypoints';

import Background from './modules/Background';
import Parallax from './modules/Parallax';
import Hero from "./modules/Hero";
import MediaSliderCarousel from "./modules/MediaSliderCarousel";
import CustomSliders from './modules/CustomSliders';
import ScrollDirection from './modules/ScrollDirection';
import Timeline from './modules/Timeline';
import StickyElement from './modules/StickyElement';
import BookingForm from '../../widgets/booking-form/js/BookingForm';
import BlogMediaPlayer from './modules/BlogMediaPlayer';
import ContentPosts from './modules/ContentPosts';

if ($('[x-data="bookingForm"]').length > 0) {
    new BookingForm()
}

new CustomSliders
new ScrollDirection('body')
new ContentPosts

$(function() {
    $('.c-hero').each( function() {
        new Hero(this)
    })
    
    $('.c-slider--media-carousel').each( function() {
        new MediaSliderCarousel(this, '.c-media')
    })
    
    $('.c-row--timeline').each( function() {
        new Timeline(this)
    })
    
    $('.c-row--four-pillars').each( function() {
        new Parallax(this, '.c-image--background', [200, 50], 0)
    })
    
    // $('.c-image--parallax').each( function() {
    //     new Parallax(this, '.c-image', -400, 0)
    // })
    
    $('[class*=panel-grid--background]').each( function() {
        new Background(this)
    })
    
    // *=====*-- allow for auto-catchup on scrollable left section --*=====* \\
    $('.c-row--text-lockup > .panel-grid-cell:first-of-type').each(function () {
        new StickyElement(this)
    })
    
    $(() => {
        // Document listeners
        $(document)
            .on('keyup', (e) => {
                if (e.key !== "Escape") return
    
                $('body').removeAttr('data-modal')
            });
    
        // media grid content height setter
        const mediaSliderGrids = $('.c-slider--media-grid')
    
        if (mediaSliderGrids.length > 0) {
            $(window).on('resize', debounce(() => {
                mediaSliderGrids.each( function() {
                    let rootElement = $(this),
                        mediaInners = rootElement.find('.c-media .c-media__inner');
    
                    // remove previous heights before calculating new max height
                    mediaInners.css({'--slider-content-height': ''})
    
                    if (window.innerWidth > 1024) return
    
                    let contentHeight = getLargestHeight(mediaInners);
    
                    mediaInners.each( (key, element) => {
                        element.style.setProperty('--slider-content-height', `${contentHeight / 16}rem`)
                    })
                })
            }, 50)).trigger('resize')
        }
    
        // *=====*-- remove sibling's ".is-active" on accordion item click --*=====* \\
        $('.c-accordion').each( function() {
            let element = $(this)
            let toggle = element.find('.c-accordion__anchor');
    
            $.each(toggle, (i, anchor) => {
                $(anchor).on('click', (event) => {
                    $(event.target).parent().siblings().removeClass('is-active').children('.c-accordion__content')
    
                    $(event.target).siblings().stop()
                })
            })
        })
    
        $('.js-booking-offcanvas-show').on('click', event => {
            $('body').attr('data-modal', 'booking')
        })
    
        $('.js-booking-offcanvas-hide').on('click', event => {
            $('body').removeAttr('data-modal')
        })
    
        $('a[href="#book"]').on('click', event => {
            event.preventDefault()
            $('body').attr('data-modal', 'booking')
        })
    
        $('.c-grid-filter--cta').each((key, element) => {
            let parent = $(element),
                grid = parent.find('.c-grid-filter__grid'),
                ctas = parent.find('.c-cta')
    
            ctas.each((key, element) => {
                let target = $(element),
                    anchor = target.find('.c-cta__anchor')
    
                if (!anchor.length > 0) return
    
                let image = target.find('.crop'),
                headings = target.find('.c-cta__headings')
    
                image.add(headings).unwrap(anchor)
                target.addClass('has-button')
            })
    
            // force grids to resize
            $(grid).isotope('layout');
        })

    
        // force flickity to correct rendering heights
        // $('.c-slider--quote').each((i, el) => {
        //     $(el).flickity('resize')
        // })
    
        // to only fire on blog inners
        if($('.body--single-post').length > 0) {
            new BlogMediaPlayer
        }
        
        // $('.c-content-posts--featured').each((key, element) => {
        //     let parent = $(element),
        //     grid = parent.find('.c-content-posts__list'),
        //     featuredCta = grid.find('.c-cta.post-category--featured');
            
        //     featuredCta.parent().addClass('featured').wrapAll('<div class="featured-post" />');
        //     console.log('featuredCta :>> ', featuredCta);
            
        //     let ctas = grid.find('.c-content-posts__post:not(.featured)');
        //     ctas.wrapAll('<div class="sub-grid" />');
        //     console.log('ctas :>> ', ctas);
        // })
    })
});


/*--------------------------------------
 Diable right clicking to help stop downloading of artist images
----------------------------------------*/
document.addEventListener('DOMContentLoaded', function() {
    // Select all img elements and elements with specified classes
    var protectedElements = document.querySelectorAll('img, .c-image, .c-media__image, .c-hero__background');
    
    // Add event listener to each element
    protectedElements.forEach(function(element) {
        // Prevent right-click on desktop
        element.addEventListener('contextmenu', function(event) {
            event.preventDefault();
            console.log('Right-click or long-press disabled on this element');
        });

        // Prevent long-press on mobile
        element.addEventListener('touchstart', function(event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });

        element.addEventListener('touchend', function(event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });

        // Prevent save image on mobile
        element.addEventListener('touchmove', function(event) {
            event.preventDefault();
        });
    });
});



/*--------------------------------------
 CTA Grid filter resize on image change
----------------------------------------*/
const targetElements = document.querySelectorAll('.c-grid-filter__grid .c-cta__image');

// Create a MutationObserver
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
      // Check if a specific class has been added
      if (mutation.target.classList.contains('has-loaded')) {
        retriggerGridFilter(mutation.target);
      }
    }
  });
});

// Observe each matching element
targetElements.forEach((element) => {
  observer.observe(element, { attributes: true });
});

function retriggerGridFilter(element) {
    $('.c-grid-filter__grid').isotope('layout');
}
